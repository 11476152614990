<!-- =========================================================================================
  File Name: DashboardAnalytics.vue
  Description: Dashboard Analytics
  ----------------------------------------------------------------------------------------
  Item Name: ESM - EASY SCHOOL MANAGEMENT
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="dashboard-analytics">
    <!-- ROW 1-->

    <div class="vx-row">
        <!-- CARD 6: Product Orders -->
        <div class="vx-col w-full mb-base">
          <div class="vx-row" v-if="showByAdmin">
            <div class="cursor-pointer vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="apporteursAffaires()">
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="UsersIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( (variable_dashboard ? variable_dashboard.contributors  : '0') )"
                statisticTitle="Apporteurs d'affaires" />
            </div>

            <div class="cursor-pointer vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="prospects()">
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="UserCheckIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( (variable_dashboard ? variable_dashboard.prospects  : '0') )"
                statisticTitle="Prospects" />
            </div>

            <div class="cursor-pointer vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="recipients()">
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="UserPlusIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( (variable_dashboard ? variable_dashboard.recipients  : '0') )"
                statisticTitle="Bénéficiaires" />
            </div>

            <div class="cursor-pointer vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="adhesions()">
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="AwardIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( (variable_dashboard ? variable_dashboard.userHasPacks  : '0') )"
                statisticTitle="Adhésions" />
            </div>
            <vx-card ref="filterCard" title="Filtre" class="user-list-filters mb-4" actionButtons @refresh="resetColFilters" >
              <div class="vx-row">
                <div class="vx-col w-1/2">
                  <p class="mb-2" > Pays </p>
                  <v-select :options="countries" :reduce="rep => rep.id" :clearable="true" label="name" v-model="country" class="w-full"/>
                </div>
                <div class="vx-col w-1/2">
                  <p class="mb-2" > Apporteur d'affaire </p>
                  <v-select :options="contributors" :reduce="rep => rep.id" :clearable="true" label="full_name" v-model="contributor" class="w-full"/>
                </div>
              </div>
              <br/>
              <vue-excel-xlsx
                :data="contributorscsv"
                :columns="columns"
                :filename="'Liste des apporteurs d\'affaires'"
                :sheetname="'sheetname'"
              >
               Exporter en csv
              </vue-excel-xlsx>


            </vx-card>

            <div class="vx-col w-full md:w-full xl:w-1/3" v-if="true">
                <statistics-card-line
                  hideChart
                  class="mb-base"
                  icon="UsersIcon"
                  icon-right
                  :statistic="new Intl.NumberFormat('de-DE').format( ( contributorInfos.prospect || 0) )"
                  statisticTitle="Nombre de clients"
                  color="danger" />
            </div>

            <div class="vx-col w-full md:w-full xl:w-1/3" v-if="true">
                <statistics-card-line
                  hideChart
                  class="mb-base"
                  icon="TrendingUpIcon"
                  icon-right
                  :statistic="new Intl.NumberFormat('de-DE').format( ( contributorInfos.commissionPaid || 0) )"
                  statisticTitle="Commission perçue"
                  color="success" />
            </div>

            <div class="vx-col w-full md:w-full xl:w-1/3" v-if="true">
                <statistics-card-line
                  hideChart
                  class="mb-base"
                  icon="TrendingUpIcon"
                  icon-right
                  :statistic="new Intl.NumberFormat('de-DE').format( ( contributorInfos.turnover || 0) )"
                  statisticTitle="Chiffre d'affaire"
                  color="warning" />
            </div>

            <div class="vx-col w-full md:w-full xl:w-1/3" v-if="false">
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="EyeIcon"
                  icon-right
                statistic="36.9k"
                statisticTitle="Views" />
            </div>

            <div class="vx-col w-full md:w-full xl:w-1/3" v-if="false">
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="MessageSquareIcon"
                  icon-right
                statisticTitle="Comments"
                statistic="12k"
                color="success" />
            </div>

            <div class="vx-col w-full md:w-full xl:w-1/3" v-if="false">
                <statistics-card-line
                  hideChart
                  class="mb-base"
                  icon="ShoppingBagIcon"
                  icon-right
                  statistic="978"
                  statisticTitle="Orders"
                  color="warning" />
            </div>

            <div class="vx-col w-full md:w-full xl:w-1/3" v-if="false">
                <statistics-card-line
                  hideChart
                  class="mb-base"
                  icon="HeartIcon"
                  icon-right
                  statistic="26.8k"
                  statisticTitle="Favorited"
                  color="danger" />
            </div>

            <div class="vx-col w-full md:w-full xl:w-1/3" v-if="false">
                <statistics-card-line
                  hideChart
                  class="mb-base"
                  icon="SmileIcon"
                  icon-right
                  statistic="689"
                  statisticTitle="Reviews"
                  color="success" />
            </div>

            <div class="vx-col w-full md:w-full xl:w-1/3" v-if="false">
                <statistics-card-line
                  hideChart
                  class="mb-base"
                  icon="TruckIcon"
                  icon-right
                  statistic="2"
                  statisticTitle="Returns"
                  color="warning" />
            </div>
          </div>

          <div class="vx-row" v-if="showByContributors">
<!--            <div class="cursor-pointer vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="prospects()">-->
<!--            <statistics-card-line-->
<!--                hideCharts-->
<!--                class="mb-base"-->
<!--                icon="UsersIcon"-->
<!--                icon-right-->
<!--                :statistic="new Intl.NumberFormat('de-DE').format( (get_contributors ? get_contributors.nbOfprospects : '0') )"-->
<!--                statisticTitle="Prospects" />-->
<!--            </div>-->

            <div class="cursor-pointer vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="prospects()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="UsersIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( (get_contributors ? get_contributors.nbOfprospects : '0') )"
                statisticTitle="Prospects" />
            </div>

            <div class="cursor-pointer vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="TrendingUpIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( (get_contributors ? get_contributors.contributor[0].commissionToPay : '0') )"
                statisticTitle="Montant commission" />
            </div>

            <div class="cursor-pointer vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="PieChartIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( (get_contributors ? get_contributors.contributor[0].commissionRate : '0'))"
                statisticTitle="Taux commission(%)" />
            </div>

            <div class="cursor-pointer vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="PieChartIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format((get_contributors ? get_contributors.contributor[0].conversionRate : '0'))"
                statisticTitle="Taux transformation(%)" />
            </div>

            <div class="vx-col w-full md:w-full xl:w-1/3" v-if="false">
                <statistics-card-line
                  hideChart
                  class="mb-base"
                  icon="HeartIcon"
                  icon-right
                  statistic="26.8k"
                  statisticTitle="Favorited"
                  color="danger" />
            </div>

            <div class="vx-col w-full md:w-full xl:w-1/3" v-if="false">
                <statistics-card-line
                  hideChart
                  class="mb-base"
                  icon="SmileIcon"
                  icon-right
                  statistic="689"
                  statisticTitle="Reviews"
                  color="success" />
            </div>

            <div class="vx-col w-full md:w-full xl:w-1/3" v-if="false">
                <statistics-card-line
                  hideChart
                  class="mb-base"
                  icon="TruckIcon"
                  icon-right
                  statistic="2"
                  statisticTitle="Returns"
                  color="warning" />
            </div>

            <div class="vx-col w-full md:w-full xl:w-1/3" v-if="false">
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="EyeIcon"
                  icon-right
                statistic="36.9k"
                statisticTitle="Views" />
            </div>

            <div class="vx-col w-full md:w-full xl:w-1/3" v-if="false">
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="MessageSquareIcon"
                  icon-right
                statisticTitle="Comments"
                statistic="12k"
                color="success" />
            </div>

            <div class="vx-col w-full md:w-full xl:w-1/3" v-if="false">
                <statistics-card-line
                  hideChart
                  class="mb-base"
                  icon="ShoppingBagIcon"
                  icon-right
                  statistic="978"
                  statisticTitle="Orders"
                  color="warning" />
            </div>

            <div class="vx-col w-full md:w-full xl:w-1/3" v-if="false">
                <statistics-card-line
                  hideChart
                  class="mb-base"
                  icon="HeartIcon"
                  icon-right
                  statistic="26.8k"
                  statisticTitle="Favorited"
                  color="danger" />
            </div>

            <div class="vx-col w-full md:w-full xl:w-1/3" v-if="false">
                <statistics-card-line
                  hideChart
                  class="mb-base"
                  icon="SmileIcon"
                  icon-right
                  statistic="689"
                  statisticTitle="Reviews"
                  color="success" />
            </div>

            <div class="vx-col w-full md:w-full xl:w-1/3" v-if="false">
                <statistics-card-line
                  hideChart
                  class="mb-base"
                  icon="TruckIcon"
                  icon-right
                  statistic="2"
                  statisticTitle="Returns"
                  color="warning" />
            </div>
          </div>
          <div class="vx-row" v-if="showByPropect">
            <div class="cursor-pointer vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2" @click="recipients()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="UsersIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format ((get_prospects ? get_prospects.nbOfRecipients : '0')  )"
                statisticTitle="Bénéficiaires" />
            </div>

            <div class="cursor-pointer vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2" @click="adhesions()">
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="AwardIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( (get_prospects ? get_prospects.nbOfUserHasPacks : '0')  )"
                statisticTitle="Adhésions" />
            </div>

<!--            <div class="cursor-pointer vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">-->
<!--              <statistics-card-line-->
<!--                hideChart-->
<!--                class="mb-base"-->
<!--                icon="TrendingUpIcon"-->
<!--                icon-right-->
<!--                :statistic="new Intl.NumberFormat('de-DE').format(  (get_prospects ? get_prospects.prospect[0].commissionToPay : '0') )"-->
<!--                statisticTitle="Montant commission" />-->
<!--            </div>-->

<!--            <div class="cursor-pointer vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" >-->
<!--              <statistics-card-line-->
<!--                hideChart-->
<!--                class="mb-base"-->
<!--                icon="PieChartIcon"-->
<!--                icon-right-->
<!--                :statistic="new Intl.NumberFormat('de-DE').format( (get_prospects ? get_prospects.prospect[0].commissionRate : '0') )"-->
<!--                statisticTitle="Taux commission(%)" />-->
<!--            </div>-->

            <div class="vx-col w-full md:w-full xl:w-1/3" v-if="false">
                <statistics-card-line
                  hideChart
                  class="mb-base"
                  icon="HeartIcon"
                  icon-right
                  statistic="26.8k"
                  statisticTitle="Favorited"
                  color="danger" />
            </div>

            <div class="vx-col w-full md:w-full xl:w-1/3" v-if="false">
                <statistics-card-line
                  hideChart
                  class="mb-base"
                  icon="SmileIcon"
                  icon-right
                  statistic="689"
                  statisticTitle="Reviews"
                  color="success" />
            </div>

            <div class="vx-col w-full md:w-full xl:w-1/3" v-if="false">
                <statistics-card-line
                  hideChart
                  class="mb-base"
                  icon="TruckIcon"
                  icon-right
                  statistic="2"
                  statisticTitle="Returns"
                  color="warning" />
            </div>

            <div class="vx-col w-full md:w-full xl:w-1/3" v-if="false">
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="EyeIcon"
                  icon-right
                statistic="36.9k"
                statisticTitle="Views" />
            </div>

            <div class="vx-col w-full md:w-full xl:w-1/3" v-if="false">
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="MessageSquareIcon"
                  icon-right
                statisticTitle="Comments"
                statistic="12k"
                color="success" />
            </div>

            <div class="vx-col w-full md:w-full xl:w-1/3" v-if="false">
                <statistics-card-line
                  hideChart
                  class="mb-base"
                  icon="ShoppingBagIcon"
                  icon-right
                  statistic="978"
                  statisticTitle="Orders"
                  color="warning" />
            </div>

            <div class="vx-col w-full md:w-full xl:w-1/3" v-if="false">
                <statistics-card-line
                  hideChart
                  class="mb-base"
                  icon="HeartIcon"
                  icon-right
                  statistic="26.8k"
                  statisticTitle="Favorited"
                  color="danger" />
            </div>

            <div class="vx-col w-full md:w-full xl:w-1/3" v-if="false">
                <statistics-card-line
                  hideChart
                  class="mb-base"
                  icon="SmileIcon"
                  icon-right
                  statistic="689"
                  statisticTitle="Reviews"
                  color="success" />
            </div>

            <div class="vx-col w-full md:w-full xl:w-1/3" v-if="false">
                <statistics-card-line
                  hideChart
                  class="mb-base"
                  icon="TruckIcon"
                  icon-right
                  statistic="2"
                  statisticTitle="Returns"
                  color="warning" />
            </div>
          </div>
        </div>
    </div>

  </div>
</template>

<script>
let base_self = ''

import VueApexCharts from 'vue-apexcharts'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import analyticsData from '@/views/ui-elements/card/analyticsData.js'
import ChangeTimeDurationDropdown from '@/components/ChangeTimeDurationDropdown.vue'
import VxTimeline from '@/components/timeline/VxTimeline'

export default {
  data () {
    return {
      columns : [
        {
          label: 'Nom',
          field: 'last_name'
        },
        {
          label: 'Prénom(s)',
          field: 'first_name'

        },
        {
          label: 'Téléphone',
          field: 'phoneNumber'
        },
        {
          label: 'Commission perçue',
          field: 'commissionPaid'
        },
        {
          label: 'Chiffre d\'affaire généré',
          field: 'turnover'
        },
        // {
        //   label: "Pays",
        //   field: "country.name",
        // },
        {
          label: 'Ville',
          field: 'city'
        }
      ],
      showByAdmin: false,
      showByPropect: false,
      showByContributors: false,
      variable_dashboard : {},
      countries: [],
      country: '',
      contributorInfos: {},
      contributors: [],
      contributorscsv: [],
      contributor:'',
      variable_prospects : {},
      get_prospects : {},
      variable_contributors : {},
      get_contributors : {},
      checkpointReward: {},
      subscribersGained: {},
      ordersRecevied: {},
      salesBarSession: {},
      supportTracker: {},
      productsOrder: {},
      salesRadar: {},

      timelineData: [
        {
          color: 'primary',
          icon: 'PlusIcon',
          title: 'Client Meeting',
          desc: 'Bonbon macaroon jelly beans gummi bears jelly lollipop apple',
          time: '25 mins Ago'
        },
        {
          color: 'warning',
          icon: 'MailIcon',
          title: 'Email Newsletter',
          desc: 'Cupcake gummi bears soufflé caramels candy',
          time: '15 Days Ago'
        },
        {
          color: 'danger',
          icon: 'UsersIcon',
          title: 'Plan Webinar',
          desc: 'Candy ice cream cake. Halvah gummi bears',
          time: '20 days ago'
        },
        {
          color: 'success',
          icon: 'LayoutIcon',
          title: 'Launch Website',
          desc: 'Candy ice cream cake. Halvah gummi bears Cupcake gummi bears soufflé caramels candy.',
          time: '25 days ago'
        },
        {
          color: 'primary',
          icon: 'TvIcon',
          title: 'Marketing',
          desc: 'Candy ice cream cake. Halvah gummi bears Cupcake gummi bears.',
          time: '28 days ago'
        }
      ],

      analyticsData,
      dispatchedOrders: []
    }
  },

  components: {
    VueApexCharts,
    StatisticsCardLine,
    ChangeTimeDurationDropdown,
    VxTimeline
  },
  watch: {
    country (value) {
      if (value) {
        this.getAllContributorsFilter(value)
      } else {
        this.getAllContributors()
      }
    },
    contributor (value) {
      if (value) {
        this.getAllContributorsinfos(value)
      }
    }
  },
  methods: {
    exportcsv () {
      return this.$http.get('export-contributors-xls/')
    },
    resetColFilters () {
      this.$refs.filterCard.removeRefreshAnimation()
    },
    getAllContributors () {
      this.$vs.loading()
      this.$http.get('contributors/')
        .then((response) => {
          this.contributors = response.data
          this.$vs.loading.close()
        })
        .catch((error) => {
          console.log('error: ', error)
          this.$vs.loading.close()
        })
    },
    getAllContributorsFilter (country) {
      this.$vs.loading()
      this.$http.post('contributor_filter/', { country })
        .then((response) => {
          this.contributors = response.data
          this.$vs.loading.close()
        })
        .catch((error) => {
          console.log('error: ', error)
          this.$vs.loading.close()
        })
    },
    getAllContributorsinfos (id) {
      this.$vs.loading()
      this.$http.post('contributorInfos/', { id })
        .then((response) => {
          this.contributorInfos = response.data
          this.$vs.loading.close()
        })
        .catch((error) => {
          console.log('error: ', error)
          this.$vs.loading.close()
        })
    },
    somme () {
      let sum = base_self.student_dashboard.nombre.number_of_all_student_in_this_year_no_abandonAtente
      sum += base_self.student_dashboard.nombre.number_of_all_abandonAtente_student_in_this_year
      sum += base_self.student_dashboard.nombre.number_of_all_abandon_student_in_this_year
      return sum
    },
    adhesions () {
      base_self.$router.push('/base/adhesions').catch(() => {})
    },
    recipients () {
      base_self.$router.push('/base/recipients').catch(() => {})
    },
    prospects () {
      base_self.$router.push('/base/prospects').catch(() => {})
    },
    apporteursAffaires () {
      base_self.$router.push('/base/apporteurs-affaires').catch(() => {})
    },
    allStudent (id) {
      base_self.$router.push(`/Student_management/Student/main/${id}`).catch(() => {})
    },
    abandonment (id) {
      base_self.$router.push(`/Student_management/Abandonment/${id}`).catch(() => {})
    },
    initialise () {
      this.productsOrder = {
        analyticsData: [
          {
            color: '#7961F9',
            counts: base_self.student_dashboard.nombre.number_of_all_student_in_this_year_no_abandonAtente,
            orderType: 'In_good_standing'
          },
          {
            color: '#FF9F43',
            counts: base_self.student_dashboard.nombre.number_of_all_abandonAtente_student_in_this_year,
            orderType: 'Abandonment_pending'
          },
          {
            color: '#EA5455',
            counts: base_self.student_dashboard.nombre.number_of_all_abandon_student_in_this_year,
            orderType: 'Abandonment'
          }
        ],
        series: [
          base_self.student_dashboard.pourcentage.number_of_all_student_in_this_year_no_abandonAtente_pourcentage,
          base_self.student_dashboard.pourcentage.number_of_all_abandonAtente_student_in_this_year_pourcentage,
          base_self.student_dashboard.pourcentage.number_of_all_abandon_student_in_this_year_pourcentage
        ]
      }

      base_self.analyticsData.productOrdersRadialBar.chartOptions.colors = ['#7961F9', '#FF9F43', '#EA5455']
      base_self.analyticsData.productOrdersRadialBar.chartOptions.labels = ['En règle', 'Abandon en attente', 'Abandon']
      base_self.analyticsData.productOrdersRadialBar.chartOptions.plotOptions.radialBar.dataLabels.total.label = 'Total'

      base_self.analyticsData = JSON.parse(JSON.stringify(base_self.analyticsData))

      setTimeout(
        function () {
          base_self.analyticsData.productOrdersRadialBar.chartOptions.labels = [base_self.$t('In_good_standing'), base_self.$t('Abandonment_pending'), base_self.$t('Abandonment')]
          base_self.analyticsData.productOrdersRadialBar.chartOptions.plotOptions.radialBar.dataLabels.total.label = base_self.$t('Total')
          base_self.analyticsData = JSON.parse(JSON.stringify(base_self.analyticsData))
        }, 7000)

    },
    async studentDashboard () {
      base_self.$vs.loading()
      base_self.$http.get(`${base_self.$store.state.route_for_esm}student-dashboard`)
        .then((response) => {
          base_self.student_dashboard = response.data.data.student_dashboard
          console.log('base_self.student_dashboard: ', base_self.student_dashboard)
          base_self.initialise()
          base_self.$vs.loading.close()
        })
        .catch(() => {
          window.getESM.error(base_self.$t('The_server_is_not_responding'))
          base_self.$vs.loading.close()
        })
    },
    dashboard () {
      this.$http.get('dashboard/')
        .then((response) => {
          this.variable_dashboard = response.data
        })
        .catch((error) => {
          console.log('error: ', error)
        })
    },
    getAllContries () {
      //delete this.$http.defaults.headers.common['Authorization']
      this.$http.get('countries/')
        .then((response) => {
          this.countries = response.data
        })
        .catch(() => {
        })
    }
  },
  created () {
    base_self = this
    window.dashboardStudent = this
    base_self.dashboard()

    const user_role = JSON.parse(localStorage.getItem('userInfo')).role
    if (user_role === 'admin') {
      this.showByAdmin = true
      this.getAllContries()

      this.$http.get('contributors/')
        .then((response) => { this.contributorscsv = response.data })
        .catch((error) => { console.log(error) })

    } else if (user_role === 'contributor') {
      this.showByContributors = true
      this.$http.get('contributorProspects/')
        .then((response) => {
          this.get_contributors = response.data
        })
        .catch((error) => {
          console.log('error: ', error)
        })

    } else if (user_role === 'prospect') {
      this.showByPropect = true
      this.$http.get('prospectRecipients/')
        .then((response) => {
          this.get_prospects = response.data
        })
        .catch((error) => {
          console.log('error: ', error)
        })

    }


    //  User Reward Card
    this.$http.get('/api/user/checkpoint-reward')
      .then((response) => { this.checkpointReward = response.data })
      .catch((error)   => { console.log(error) })

      // Subscribers gained - Statistics
    this.$http.get('/api/card/card-statistics/subscribers')
      .then((response) => { this.subscribersGained = response.data })
      .catch((error)   => { console.log(error) })

      // Orders - Statistics
    this.$http.get('/api/card/card-statistics/orders')
      .then((response) => { this.ordersRecevied = response.data })
      .catch((error)   => { console.log(error) })

      // Sales bar - Analytics
    this.$http.get('/api/card/card-analytics/sales/bar')
      .then((response) => { this.salesBarSession = response.data })
      .catch((error)   => { console.log(error) })

      // Support Tracker
    this.$http.get('/api/card/card-analytics/support-tracker')
      .then((response) => { this.supportTracker = response.data })
      .catch((error)   => { console.log(error) })

      // Products Order
    /* this.$http.get('/api/card/card-analytics/products-orders')
      .then((response) => {
        // this.productsOrder = response.data


        // console.log('this.productsOrder: ', this.productsOrder)
      })
      .catch((error)   => { console.log(error) }) */

      // Sales Radar
    this.$http.get('/api/card/card-analytics/sales/radar')
      .then((response) => { this.salesRadar = response.data })
      .catch((error)   => { console.log(error) })

      // Dispatched Orders
    this.$http.get('/api/table/dispatched-orders')
      .then((response) => { this.dispatchedOrders = response.data })
      .catch((error)   => { console.log(error) })
  }
}
</script>

<style lang="scss">
/*! rtl:begin:ignore */
#dashboard-analytics {
  .greet-user{
    position: relative;

    .decore-left{
      position: absolute;
      left:0;
      top: 0;
    }
    .decore-right{
      position: absolute;
      right:0;
      top: 0;
    }
  }

  @media(max-width: 576px) {
    .decore-left, .decore-right{
      width: 140px;
    }
  }
}
/*! rtl:end:ignore */
</style>
